import { getToken,axiosInstance } from "../utils/Api";

export async function getAllGrades() {
  try {  
    const response = await axiosInstance.get(`/grade/getAllGrades`, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      });
    return response.data;
  } catch (error) {
    console.error('Error occurred while fetching grades:', error);
    return false;
  }
}
export async function getAllSubjects() {
  try {  
    const response = await axiosInstance.get(`/concept/getAllConcepts`, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      });
    return response.data;
  } catch (error) {
    console.error('Error occurred while fetching subjects:', error);
    return false;
  }
}
 