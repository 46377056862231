// reducers/apiReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchMarketingBanners,fetchGrades,FetchHomePage,FetchUserData, fetchProfilePicture, uploadProfilePicture } from '../actions/homePageActions';

const apiSlice = createSlice({
  name: 'api',
  initialState: { 
    loading: false,
    marketing_banners: [],
    grades:[],
    HomeData:[],
    UserData:[],
    profilePicture: {
      url: "/svgs/profile-Avathar.svg",
    },
    error: null,
  },
  extraReducers: builder => {
    builder
    .addCase(FetchHomePage.fulfilled, (state, action) => { 
      state.loading = false;
      state.HomeData = action.payload;
    })
    .addCase(fetchMarketingBanners.fulfilled, (state, action) => { 
      state.loading = false;
      state.marketing_banners = action.payload;
    })
    .addCase(fetchGrades.fulfilled, (state, action) => { 
      state.loading = false;
      state.grades = action.payload;
    })
    .addCase(FetchUserData.fulfilled, (state, action) => { 
      state.loading = false;
      state.UserData = action.payload;
    })
    .addCase(fetchProfilePicture.fulfilled, (state, action) => {
      state.profilePicture.url = action.payload.url;
    })
    .addCase(uploadProfilePicture.fulfilled, (state, action) => {
        state.profilePicture.url = action.payload.url;
        state.profilePicture.changed = action.payload.changed;
      })
      // .addCase(fetchGrades.pending, state => {
      //   state.loading = true;
      //   state.error = null;
      // }) 
      // .addCase(fetchGrades.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // });
  },
});

export default apiSlice.reducer;
