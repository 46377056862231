import React from "react";
import { Link } from "react-router-dom";
import "./ErrorComponent.css";

function ErrorComponent() {
  return (
    <div className="error-component">
      <h2>Oops!! This page is not avaliable.</h2>
      <Link to={`/home`} className="home-button">
        Return to Home page
      </Link>
    </div>
  );
}

export default ErrorComponent;
