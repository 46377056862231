import { getToken,axiosInstance } from "../utils/Api";

export async function getMarketingBanners() {
  try {
    const url = `/user/getMarketingBanners`;
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    };
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Error occurred while fetching marketing banners:', error);
    return false;
  }
}
 
export async function getHomeData() {
  try {
    const url = `/homepage/data`;
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    };
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Error occurred while fetching marketing banners:', error);
    return false;
  }
}